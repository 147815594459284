@use 'src/theme/helpers' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/breakpoints.module' as *;

.root {
  background: var(--for-neu-tie2);
  padding: pxToRem(12) pxToRem(16);
  border-radius: pxToRem(6);
  margin-bottom: pxToRem(16);
}

.title {
  margin: 0 0 pxToRem(8);
  @include fontSize(17, 24);
  font-weight: 400;
}

.car {
  display: grid;
  grid-template:
    '🚗 ✨' auto
    '🚗 🎛' auto / pxToRem(120) auto;
  column-gap: pxToRem(16);
  row-gap: pxToRem(2);
  position: relative;
  isolation: isolate;
}

.carLink {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  &:hover,
  &:focus-visible {
    outline: 0;
    ~ .carTitle {
      text-decoration: underline;
    }
  }
}

.carImage {
  grid-area: 🚗;
  width: pxToRem(120);
  height: pxToRem(68);
  border-radius: var(--cor_rad-xs);
  position: relative;
  overflow: hidden;
}

.carTitle {
  grid-area: ✨;
  align-self: flex-end;
  margin: 0;
  @include fontSize(17, 24);
  font-weight: 800;
}

.carSubtitle {
  grid-area: 🎛;
  margin: 0;
  @include fontSize(15, 20);
}

.footer {
  margin-top: pxToRem(12);
  padding-top: pxToRem(14);
  display: grid;
  grid-template: '⏰ 😀' 1fr / 1fr auto;
  border-top: $border-0;
  align-items: center;
}

.footerInfoLabel {
  @include fontSize(15, 20);
  font-weight: 800;
  margin: 0 0 pxToRem(2);
}

.footerInfoPrice {
  @include fontSize(26, 32);
  font-weight: 800;
}
