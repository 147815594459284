@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;
@use 'src/theme/zindexes.module' as *;

$transition: ease-in-out 200ms;

.root {
  background: rgba(0, 0, 0, 0);
  padding: pxToRem(6);
  border: thin solid $color-uiPrimary-050;
  border-radius: pxToRem(20);
  box-sizing: border-box;
  color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background $transition;

  &:not(:disabled) {
    cursor: pointer;

    &:focus-visible,
    &:hover {
      background: $color-uiPrimary-050;
      color: $color-uiPrimary-600;
    }

    &:active {
      background: $color-uiPrimary-100;
    }
  }

  .menuIcon {
    margin-right: pxToRem(8);
  }
}

.popperOpen {
  background: $color-uiPrimary-100;
}

.caretContainer {
  width: 0;
  height: pxToRem(24);
  overflow: hidden;
  transition:
    width $transition,
    opacity $transition,
    padding $transition;
}

:global(body:has(.header-homepage)),
:global(#__next:has(.header-homepage)) {
  &:global(.scroll-top) {
    .root {
      &:not(:disabled) {
        &:focus-visible,
        &:hover,
        &:active {
          border-color: transparent;
          background: $color-opacity-light-lowEmphasis;
          color: var(--tex-inv-tie1);
        }
      }
    }

    .caretIcon {
      color: var(--tex-inv-tie1);
    }

    .popperOpen {
      background: var(--bac-tie1);

      .caretIcon {
        color: var(--tex-def-tie1);
      }
    }
  }
}

@include md {
  .root {
    border-color: transparent;

    &.loggedIn {
      border-color: $color-uiPrimary-050;

      .caretContainer {
        width: pxToRem(24 + 8);
        padding-left: pxToRem(8);
        opacity: 1;
      }
    }

    .menuIcon {
      display: none;
    }
  }
}
