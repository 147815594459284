@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

$transition: ease-in-out 200ms;

.root {
  width: pxToRem(28);
  height: pxToRem(28);
  position: relative;

  .loader {
    position: absolute;
    top: pxToRem(-2);
    left: pxToRem(-2);
    font-size: pxToRem(32);
  }

  &.notification::after {
    content: '';
    position: absolute;
    top: pxToRem(-1);
    right: pxToRem(-1);
    background: $color-brandPrimary-100;
    width: pxToRem(8);
    height: pxToRem(8);
    border-radius: 50%;
  }

  &.authenticated {
    .avatarGeneric {
      opacity: 0;
    }
    .avatarProfile {
      opacity: 1;
    }
  }
}

.avatar {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  transition: opacity $transition;
  color: $color-uiPrimary-600;
  background: var(--bac-tie1);
  display: flex;
  align-items: center;
  justify-content: center;
}

.avatarProfile {
  @include fontSize(11, 28);
  font-weight: 800;
  background: var(--bac-tie1);
  color: var(--tex-def-tie1);
  opacity: 0;
  border: pxToRem(1) solid $color-uiPrimary-050;
}

.avatarProfileImage {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

:global(body:has(.header-transparent)),
:global(#__next:has(.header-transparent)) {
  &:global(.scroll-top) {
    .avatar {
      background: var(--bac-tie1);
      border: pxToRem(1) solid $color-uiPrimary-050;
      color: var(--tex-def-tie1);
    }
  }
}
