@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;
@use 'src/theme/spacings.module' as *;

.menuPanel {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--bac-tie1);
}

.menuPanelTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: pxToRem(8);
  margin-left: pxToRem(-8);

  h2 {
    @include fontSize(26, 32);
  }
}

.topBar {
  height: $headerHeight;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 pxToRem(20) 0 pxToRem(16);
}

.logoImage {
  margin-left: pxToRem(-32);
  margin-bottom: pxToRem(-6);
  height: pxToRem(70);
}

.scrollBox {
  height: calc(100% - $headerHeight);
  overflow-x: hidden;
  overflow-y: auto;
  border-top: thin solid transparent;
  padding: pxToRem(16) pxToRem(8);
  width: 100%;
  box-sizing: border-box;

  &:global(.overflow-top) {
    border-top-color: $color-neutral-300;
  }
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  row-gap: pxToRem(4);

  .button {
    padding-left: pxToRem(16);
    padding-right: pxToRem(16);
  }
}

.menuAccount {
  display: none;
}

.listItemBorderTop {
  border-top: $border-0;
  padding-top: pxToRem(4);
}
.listItemBorderBottom {
  border-bottom: $border-0;
  padding-bottom: pxToRem(4);
}

.userData {
  padding: 0 pxToRem(16) pxToRem(8);
  @include fontSize(14, 18);
}

.userDataSalutations {
  @include fontSize(20, 28);
  font-weight: 800;
}

.menuItem {
  @include fontSize(17, 24);
  display: flex;
  width: 100%;
  justify-content: flex-start;
  padding: pxToRem(14) pxToRem(16);
  border-radius: var(--cor_rad-xs);
  text-decoration: none;
  color: inherit;
  transition: background ease-in-out 200ms;
  border: none;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  font-family: inherit;
  box-sizing: border-box;

  &:hover,
  &:focus-visible {
    background: $color-uiPrimary-050;
  }

  &.menuItemEndIcon {
    justify-content: space-between;
  }
}

.menuItemAccount {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: pxToRem(8);

  :global(.UserAvatar) {
    margin: pxToRem(-2) 0;
  }
}

@include sm {
  .logoImage {
    margin-left: pxToRem(-16);
  }
}

@include md {
  :global(.desktopMenu) {
    &.menuPanel {
      position: relative;
    }
    .topBar {
      display: none;
    }
    .menuRoot {
      display: none;
    }
    .menuAccount {
      display: block;
    }
  }
}
