@use 'src/theme/helpers' as *;
@use 'src/theme/breakpoints.module' as *;
@use 'src/theme/color.module' as *;

.root {
  display: flex;
  position: relative;
  width: 1.5em;
  height: 1.5em;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  --chevron-bg-color: #{$color-uiPrimary-200};
  --chevron-color: #{$color-uiPrimary-900};

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    background: var(--chevron-bg-color);
    width: 1em;
    height: 1em;
    margin: -0.5em;
    transition: background ease-in-out 200ms;
  }

  :global(.icon) {
    position: relative;
    color: var(--chevron-color);
    transition: color ease-in-out 200ms;
  }
}

a,
button {
  &:hover,
  &:focus-visible {
    .root {
      --chevron-bg-color: #{$color-uiPrimary-900};
      --chevron-color: #{$color-neutral-000};
    }
  }
}
